<template>
    <highcharts  class="chart w-full h-full" :options="options" :ref="chartId" id="chartId"></highcharts>
</template>

<script>
import {reduceBigNumbers} from '../main.js'
let data = {}
const moment = require('moment')
    
export default {
    props: ['data', 'chartId', 'text_option'],
    data: function(){
        return {
            options: {
                chart: {
                    type: 'areaspline',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 0,
                    marginTop: 20
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: {
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        style:{
                            color:'#768194'
                        }
                    },
                    tickWidth: 0,
                    type: 'datetime',
                },
                exporting:{
                    enabled:false
                },
                yAxis:[{
                    title: {
                        text: '',
                    },
                    // type: 'logarithmic',
                    gridLineColor: '#AFA9A9',
                    labels: {
                        enabled: true,
                        // format: '{value}'
                        formatter: function(){
                            return reduceBigNumbers(this.value,2)
                        }
                    },
                    tickAmount: 4,
                }],
                tooltip: {
                    enabled:true,
                    formatter: function() {
                        return this.series.name + ' <b>' + reduceBigNumbers(this.y) + '</b>';
                    },
                },
                plotOptions: {
                    areaspline:{
                        fillOpacity:.2,
                        lineWidth:3,
                        marker:{
                            enabled:false
                        },
                        shadow: {
                            offsetX: 2,
                            offsetY: 4,
                            opacity: .2,
                            width: 4,
                        },
                        dataLabels:{
                            enabled: true,
                            formatter: function() {
                                if (this.y === this.series.dataMax ) {
                                    return '<div class="px-1 rounded-md text-sky text-xs border-2 border-sky">'+reduceBigNumbers(this.y)+'</div>';
                                }
                            },
                            crop: false,
                            overflow: 'none',
                            useHTML: true,
                            style:{
                                fontSize: "13px",
                                fontWeight:"bold",
                                textOutline:"0px",
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name:this.$t(this.text_option),
                        data:[],
                        color: '#4FB9FF',
                    },
                    {
                        name:'Inversión',
                        data:[],
                        color: '#69D4ED',
                    },
                ]
            }
        }
    },
    methods: {
        load() {
            let chart = this.$refs[this.chartId].chart;

            let arrayData = []
            let maxValue = Math.max(this.data)

            for (let index = 0; index < this.data.length; index++) {
                
                if(this.data[index] == maxValue){
                    arrayData.push({
                        y:this.data[index]
                    })
                } else {
                    arrayData.push(this.data[index])
                }
                
            }

            chart.series[0].setData(this.data)
        },
    },
    watch: {
        data: {
            handler(a, b){
                this.load();
            },
            deep: true
        }
    },
    mounted() {
        data = this.data
        this.textToBeTranslated = this.$t(this.text_option)
        this.load();
    }
};
</script>
<style lang="scss">

</style>
